// mixins 
@mixin textHidden {text-indent: 130%;white-space: nowrap;overflow: hidden; }

@mixin transition($value: .3s) {
    -moz-transition: $value ease;
	-webkit-transition: $value ease;
	-o-transition: $value ease;
	-ms-transition: $value ease;
	transition: $value ease;
}

@mixin rotate($value: 90deg) {
	-webkit-transform: rotate($value);
    -moz-transform: rotate($value);
    -ms-transform: rotate($value);
    -o-transform: rotate($value);
    transform: rotate($value);
     zoom:1;
}

@mixin max-screen($break-point) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}

@mixin min-screen($break-point) {
  @media screen and (min-width: $break-point) {
    @content;
  }
}

@mixin screen($break-point-min, $break-point-max) {
  @media screen and (min-width: $break-point-min) and (max-width: $break-point-max) {
    @content;
  }
}