// ------ import config ------
@import "lib/config";
// ------ import custom mixins ------
@import "lib/mixins";

/*!
*  - v1.0.2
* Homepage: http://bqworks.com/slider-pro/
* Author: bqworks
* Author URL: http://bqworks.com/
*/
/* Core
--------------------------------------------------*/
.slider-pro {
	position: relative;
	margin: 0 auto;
}

.sp-slides-container {
	position: relative;
}

.sp-mask {
	position: relative;
	overflow: hidden;
}

.sp-slides {
	position: relative;
	-webkit-backface-visibility: hidden;
	-webkit-perspective: 1000;
}

.sp-slide {
	position: absolute;
}

.sp-image-container {
	overflow: hidden;
}

.sp-image {
	position: relative;
	display: block;
	border: none;
}

/* Thumbnails
--------------------------------------------------*/
.sp-thumbnails-container {
	position: relative;
	overflow: hidden;
}

.sp-top-thumbnails,
.sp-bottom-thumbnails  {
	left: 0;
	margin: 0 auto;
}

.sp-top-thumbnails {
	position: absolute;
	top: 0;
}

.sp-left-thumbnails,
.sp-right-thumbnails {
	position: absolute;
	top: 0;
}

.sp-right-thumbnails {
	right: 0;
}

.sp-left-thumbnails {
	left: 0;
}

.sp-thumbnails {
	position: relative;
}

.sp-thumbnail {
    border: none;
}

.sp-thumbnail-container {
	position: relative;
	display: block;
	overflow: hidden;
	float: left;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

/* Horizontal thumbnails
------------------------*/
.sp-top-thumbnails .sp-thumbnail-container,
.sp-bottom-thumbnails .sp-thumbnail-container {
	margin-left: 0;
	margin-right: 0;
}

.sp-top-thumbnails .sp-thumbnail-container:first-child,
.sp-bottom-thumbnails .sp-thumbnail-container:first-child {
	margin-left: 0;
}

.sp-top-thumbnails .sp-thumbnail-container:last-child,
.sp-bottom-thumbnails .sp-thumbnail-container:last-child {
	margin-right: 0;
}

/* Vertical thumbnails 
----------------------*/
.sp-left-thumbnails .sp-thumbnail-container,
.sp-right-thumbnails .sp-thumbnail-container {
	margin-top: 0;
	margin-bottom: 0;
}

.sp-left-thumbnails .sp-thumbnail-container:first-child,
.sp-right-thumbnails .sp-thumbnail-container:first-child {
	margin-top: 0;
}

.sp-left-thumbnails .sp-thumbnail-container:last-child,
.sp-right-thumbnails .sp-thumbnail-container:last-child {
	margin-bottom: 0;
}

/* Right thumbnails with pointer
--------------------------------*/
.sp-right-thumbnails.sp-has-pointer {
	margin-left: -13px;
}

.sp-right-thumbnails.sp-has-pointer .sp-thumbnail {
	position: absolute;
	left: 18px;
	margin-left: 0 !important;
}

.sp-right-thumbnails.sp-has-pointer .sp-selected-thumbnail:before {
	content: '';
	position: absolute;
	height: 100%;
	border-left: 5px solid #F00;
	left: 0;
	top: 0;
	margin-left: 13px;
}

.sp-right-thumbnails.sp-has-pointer .sp-selected-thumbnail:after {
	content: '\25c0';
	position: absolute;
	font-family: Arial;
	font-size: 16px;
	line-height: 1;
	color: #F00;
	left: 0;
	top: 50%;
	margin-top: -6px;
}

.ie11 .sp-right-thumbnails.sp-has-pointer .sp-selected-thumbnail:after, 
.ie10 .sp-right-thumbnails.sp-has-pointer .sp-selected-thumbnail:after, 
.ie9 .sp-right-thumbnails.sp-has-pointer .sp-selected-thumbnail:after {
	content: '\25bc';
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

/* Left thumbnails with pointer
-------------------------------*/
.sp-left-thumbnails.sp-has-pointer {
	margin-right: -13px;
}

.sp-left-thumbnails.sp-has-pointer .sp-thumbnail {
	position: absolute;
	right: 18px;
}

.sp-left-thumbnails.sp-has-pointer .sp-selected-thumbnail:before {
	content: '';
	position: absolute;
	height: 100%;
	border-left: 5px solid #F00;
	right: 0;
	top: 0;
	margin-right: 13px;
}

.sp-left-thumbnails.sp-has-pointer .sp-selected-thumbnail:after {
	content: '\25ba';
	position: absolute;
	font-family: Arial;
	font-size: 16px;
	line-height: 1;
	color: #F00;
	right: 0;
	top: 50%;
	margin-top: -6px;
}

.ie11 .sp-left-thumbnails.sp-has-pointer .sp-selected-thumbnail:after,
.ie10 .sp-left-thumbnails.sp-has-pointer .sp-selected-thumbnail:after,
.ie9 .sp-left-thumbnails.sp-has-pointer .sp-selected-thumbnail:after {
	content: '\25b2';
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

/* Bottom thumbnails with pointer
---------------------------------*/
.sp-bottom-thumbnails.sp-has-pointer {
	margin-top: -13px;
}

.sp-bottom-thumbnails.sp-has-pointer .sp-thumbnail {
	position: absolute;
	top: 18px;
	margin-top: 0 !important;
}

.sp-bottom-thumbnails.sp-has-pointer .sp-selected-thumbnail:before {
	content: '';
	position: absolute;
	width: 100%;
	border-bottom: 5px solid #F00;
	top: 0;
	margin-top: 13px;
}

.sp-bottom-thumbnails.sp-has-pointer .sp-selected-thumbnail:after {
	content: '\25b2';
	position: absolute;
	font-family: Arial;
	font-size: 16px;
	line-height: 1;
	color: #F00;
	left: 50%;
	top: 0;
	margin-left: -9px;
}

/* Top thumbnails with pointer
------------------------------*/
.sp-top-thumbnails.sp-has-pointer {
	margin-bottom: -13px;
}

.sp-top-thumbnails.sp-has-pointer .sp-thumbnail {
	position: absolute;
	bottom: 18px;
}

.sp-top-thumbnails.sp-has-pointer .sp-selected-thumbnail:before {
	content: '';
	position: absolute;
	width: 100%;
	border-bottom: 5px solid #F00;
	bottom: 0;
	margin-bottom: 13px;
}

.sp-top-thumbnails.sp-has-pointer .sp-selected-thumbnail:after {
	content: '\25bc';
	position: absolute;
	font-family: Arial;
	font-size: 16px;
	line-height: 1;
	color: #F00;
	left: 50%;
	bottom: 0;
	margin-left: -9px;
}

/* Layers
--------------------------------------------------*/
.sp-layer {
	position: absolute;
	margin: 0;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-webkit-font-smoothing: subpixel-antialiased;
	-webkit-backface-visibility: hidden;
}

//.sp-black {
//	color: #FFF;
//	background: rgb(0, 0, 0);
//	background: rgba(0, 0, 0, 0.7);
//}

//.sp-white {
//	color: #000;
//	background: rgb(255, 255, 255);
//	background: rgba(255, 255, 255, 0.7);
//}

//.sp-rounded {
//	border-radius: 10px;
//}

//.sp-padding {
//	padding: 10px;
//}

///* Touch Swipe
//--------------------------------------------------*/
//.sp-grab {
//	cursor: url(images/openhand.cur), move;
//}

//.sp-grabbing {
//	cursor: url(images/closedhand.cur), move;
//}

//.sp-selectable {
//	cursor: default;
//}

///* Caption
//--------------------------------------------------*/
//.sp-caption-container {
//	text-align: center;
//	margin-top: 10px;
//}

/* Full Screen
--------------------------------------------------*/
.sp-full-screen {
	margin: 0 !important;
	background-color: #000;
}

.sp-full-screen-button {
	position: absolute;
	top: 5px;
	right: 10px;
	font-size: 30px;
	line-height: 1;
	cursor: pointer;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.sp-full-screen-button:before {
	content: '\2195';
}

.sp-fade-full-screen {
	opacity: 0;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
}

.slider-pro:hover .sp-fade-full-screen {
	opacity: 1;
}

/* Buttons
--------------------------------------------------*/
.sp-buttons {
	position: relative;
	width: 100%;
	text-align: center;
	margin-top: -20px;
}

.sp-button {
	width: 7px;
	height: 7px;
	border-radius: 50%;
	margin: 4px;
	display: inline-block;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
	background-color: rgba($white,.8);
}

.sp-selected-button {
	background-color: $main-orange;
}

//@media only screen and (min-width: 768px) and (max-width: 1024px) {
//	.sp-button {
//		width: 14px;
//		height: 14px;
//	}
//
//	.sp-has-buttons {
//		margin-bottom: 36px;
//	}
//}

//@media only screen and (min-width: 568px) and (max-width: 768px) {
//	.sp-button {
//		width: 16px;
//		height: 16px;
//	}
//
//	.sp-has-buttons {
//		margin-bottom: 38px;
//	}
//}

//@media only screen and (min-width: 320px) and (max-width: 568px) {
//	.sp-button {
//		width: 18px;
//		height: 18px;
//	}
//
//	.sp-has-buttons {
//		margin-bottom: 40px;
//	}
//}

/* Arrows
--------------------------------------------------*/
.sp-fade-arrows {
	//opacity: 0;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
}

.sp-slides-container:hover .sp-fade-arrows {
	opacity: 1;
}

.sp-arrow {
	//position: absolute;
	//font-size: 40px;
	//color: #FFF;
	//text-shadow: 1px 1px 1px #666;
	//cursor: pointer;
	position: absolute;
	display: block;
	top: 50%;
	margin-top: -10px;
	outline: 0;
	width: 20px;
	height: 20px;
	text-indent: -9999px;
	//z-index: 10;
	border-top: 2px solid $white;
	border-right: 2px solid $white;
	@include transition(.3s);
	z-index: 10;
}

//.sp-vertical .sp-arrow {
//	-webkit-transform: rotate(90deg);
//	-ms-transform: rotate(90deg);
//	transform: rotate(90deg);
//}

.sp-previous-arrow {
	left: 25px;
	@include rotate(-135deg);
	@include max-screen($tabletBreak) {
		left: 15px;
	}
}
.sp-next-arrow {
	right: 25px;
	@include rotate(45deg);
	@include max-screen($tabletBreak) {
		right: 15px;
	}
}

//.sp-horizontal .sp-arrow {
//	top: 50%;
//	margin-top: -20px;
//}

//.sp-vertical .sp-arrow {
//	left: 50%;
//	margin-left: -10px;
//}

//.sp-previous-arrow:before {
//	content: '\276e';
//}

//.ios .sp-previous-arrow:before,
//.ie9 .sp-previous-arrow:before,
//.ie8 .sp-previous-arrow:before,
//.ie7 .sp-previous-arrow:before {
//	content: '\25c0';
//}

//.ie8.sp-vertical .sp-previous-arrow:before,
//.ie7.sp-vertical .sp-previous-arrow:before {
//	content: '\25b2';
//}

//.sp-next-arrow:before {
//	content: '\276f';
//}

//.ios .sp-next-arrow:before,
//.ie9 .sp-next-arrow:before,
//.ie8 .sp-next-arrow:before,
//.ie7 .sp-next-arrow:before {
//	content: '\25b6';
//}

//.ie8.sp-vertical .sp-next-arrow:before,
//.ie7.sp-vertical .sp-next-arrow:before {
//	content: '\25bc';
//}

//.sp-horizontal .sp-previous-arrow {
//	left: 20px;
//}

//.sp-horizontal .sp-next-arrow {
//	right: 20px;
//}

//.sp-vertical .sp-previous-arrow {
//	top: 20px;
//}

//.sp-vertical .sp-next-arrow {
//	bottom: 20px;
//}

/* Thumbnail Arrows
--------------------------------------------------*/
.sp-thumbnail-arrows {
	position: absolute;
}

.sp-fade-thumbnail-arrows {
	opacity: 0;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
}

.sp-thumbnails-container:hover .sp-fade-thumbnail-arrows {
	opacity: 1;
}

.sp-top-thumbnails .sp-thumbnail-arrows,
.sp-bottom-thumbnails .sp-thumbnail-arrows {
	width: 100%;
	top: 50%;
	left: 0;
	margin-top: -10px;
}

.sp-left-thumbnails .sp-thumbnail-arrows,
.sp-right-thumbnails .sp-thumbnail-arrows {
	height: 100%;
	top: 0;
	left: 50%;
	margin-left: -5px;
}

.sp-thumbnail-arrow {
	position: absolute;
	font-size: 20px;
	color: #FFF;
	text-shadow: 1px 1px 1px #666;
	cursor: pointer;
}

.sp-left-thumbnails .sp-thumbnail-arrows .sp-thumbnail-arrow,
.sp-right-thumbnails .sp-thumbnail-arrows .sp-thumbnail-arrow {
	-ms-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}

.sp-previous-thumbnail-arrow:before {
	content: '\276e';
}

.ios .sp-previous-thumbnail-arrow:before,
.ie7 .sp-previous-thumbnail-arrow:before,
.ie8 .sp-previous-thumbnail-arrow:before,
.ie9 .sp-previous-thumbnail-arrow:before {
	content: '\25c0';
}

.ie8.sp-vertical .sp-previous-thumbnail-arrow:before,
.ie7.sp-vertical .sp-previous-thumbnail-arrow:before {
	content: '\25b2';
}

.sp-next-thumbnail-arrow:before {
	content: '\276f';
}

.ios .sp-next-thumbnail-arrow:before,
.ie7 .sp-next-thumbnail-arrow:before,
.ie8 .sp-next-thumbnail-arrow:before,
.ie9 .sp-next-thumbnail-arrow:before {
	content: '\25b6';
}

.ie8.sp-vertical .sp-next-thumbnail-arrow:before,
.ie7.sp-vertical .sp-next-thumbnail-arrow:before {
	content: '\25bc';
}

.sp-top-thumbnails .sp-previous-thumbnail-arrow,
.sp-bottom-thumbnails .sp-previous-thumbnail-arrow {
	left: 0px;
}

.sp-top-thumbnails .sp-next-thumbnail-arrow,
.sp-bottom-thumbnails .sp-next-thumbnail-arrow {
	right: 0px;
}

.sp-left-thumbnails .sp-previous-thumbnail-arrow,
.sp-right-thumbnails .sp-previous-thumbnail-arrow {
	top: 0px;
}

.sp-left-thumbnails .sp-next-thumbnail-arrow,
.sp-right-thumbnails .sp-next-thumbnail-arrow {
	bottom: 0px;
}

///* Video
//--------------------------------------------------*/
//a.sp-video {
//	text-decoration: none;
//}

//a.sp-video img {
//	-webkit-backface-visibility: hidden;
//	border: none;
//}

//a.sp-video:after {
//	content: '\25B6';
//	position: absolute;
//	width: 45px;
//	padding-left: 5px;
//	height: 50px;
//	border: 2px solid #FFF;
//	text-align: center;
//	font-size: 30px;
//	border-radius: 30px;
//	top: 0;
//	color: #FFF;
//	bottom: 0;
//	left: 0;
//	right: 0;
//	background-color: rgba(0, 0, 0, 0.2);
//	margin: auto;
//	line-height: 52px;
//}