// ----------------------------------------
// // ------ font ------
// ----------------------------------------
$font-family: YuGothic, '游ゴシック',"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ","Meiryo","ＭＳ Ｐゴシック","MS P Gothic",Helvetica,Arial,Verdana,sans-serif,icon;
$font-family-Min:"游明朝","Yu Mincho","游明朝体","YuMincho","ヒラギノ明朝 Pro W3","Hiragino Mincho Pro","HiraMinProN-W3","HGS明朝E","ＭＳ Ｐ明朝","MS PMincho",serif;

$font-size: 14px; 
$font-size-sp: 12px;

$line-height: 180%;
$line-height-sp: 190%;

// ----------------------------------------
// // ------ colors ------
// ----------------------------------------
$color: #595959;//カラー グレー
$main-color: #033161; //メインカラー 紺
$main-orange: #dc3a12; //メインオレンジ
$bg-color: #f6f6f7; //背景色
$border-color: #b3b3b3; //グレー

$darkBlue: #0d1d3b;
$white: #FFFFFF;
$black: #000000;
$lightGray: #f4f4f4;
$gray: #b3b3b3;
$darkGray: #262626;

$link: $main-color;
$visit: $main-color;
$hover: $main-color;
$active: $main-color;


// ----------------------------------------
// // ----Break Point ----
// ----------------------------------------
$maxWidth: 1280px;
$contentsWidth:1024px;
$tabletBreak: 768px;
$smpBreak: 640px;
